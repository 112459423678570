<template>
  <div class="wrapper statistics">
    <div class="statistics-header">
      <div class="left">
        <el-select @change="areaChange" class="select-area" v-model="areaValue" placeholder="请选择">
          <el-option v-for="item in areaOptions" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
        <el-date-picker @change="monthChange" class="picker-month" value-format="yyyy-MM" v-model="monthValue"
                        type="month" placeholder="选择月" align="center"></el-date-picker>
        <div class="main-nav">
          <div class="nav-item" :class="activeIndex==index?'active':''" v-for="(item,index) in navList" :key="item.id"
               @click="navItem(index)">{{ item.name }}
          </div>
        </div>
        <el-button size="small" type="primary" @click="exportLogisticsInfos">导出</el-button>
      </div>
      <div class="right entry-status">
        <div class="title">物流单录入情况</div>
        <div class="entry-status-item">
          <div class="name">总导入订单数</div>
          <div class="content">
            <span>淄博:<em>{{ orderNumberZb }}</em></span>
            <span>成都:<em>{{ orderNumberCd }}</em></span>
            <span>西安:<em>{{ orderNumberXa }}</em></span>
          </div>
        </div>
        <div class="entry-status-item">
          <div class="name">待审核数量</div>
          <div class="content">
            <span>淄博:<em>{{ checkNumberZb }}</em></span>
            <span>成都:<em>{{ checkNumberCd }}</em></span>
            <span>西安:<em>{{ checkNumberXa }}</em></span>
          </div>
        </div>
        <div class="entry-status-item">
          <div class="name">客服驳回数量</div>
          <div class="content">
            <span @click="downloadOrderNumList(deApprovalNumberZb,'淄博')">淄博:<em>{{ deApprovalNumberZb.length }}</em></span>
            <span @click="downloadOrderNumList(deApprovalNumberCd,'成都')">成都:<em>{{ deApprovalNumberCd.length }}</em></span>
            <span @click="downloadOrderNumList(deApprovalNumberXa,'西安')">西安:<em>{{ deApprovalNumberXa.length }}</em></span>
          </div>
        </div>
      </div>

    </div>
    <el-row :gutter="15">
      <el-col :md="24" :lg="12">
        <overallData :regionId="areaValue" :month="monthValue" :province="activeIndex"></overallData>
      </el-col>
      <el-col :md="24" :lg="12">
        <areaProportion></areaProportion>
      </el-col>
    </el-row>
    <el-row :gutter="15" style="margin-top:15px;">
      <el-col :md="14" :lg="8">
        <proportionCharts></proportionCharts>
      </el-col>
      <el-col :md="10" :lg="4">
        <query></query>
        <!-- <doubtOrder></doubtOrder> -->
      </el-col>
      <el-col :md="24" :lg="12">
        <shipperList :regionId="areaValue" :month="monthValue" :province="activeIndex"></shipperList>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import overallData from './components/overallData.vue'
import areaProportion from './components/areaProportion.vue'
import proportionCharts from './components/proportionCharts.vue'
// import doubtOrder from './components/doubtOrder.vue'
import query from './components/query.vue'
import shipperList from './components/shipperList.vue'
import {
  examineStatistics, deApprovalStatistics, exportLogisticsInfos
} from '@/api/statistic'
import {getDate} from "@/common/common";

export default {
  components: {
    overallData,
    areaProportion,
    proportionCharts,
    // doubtOrder,
    query,
    shipperList
  },
  data() {
    return {
      areaOptions: [{
        value: 1,
        label: '淄博'
      }, {
        value: 2,
        label: '成都'
      }, {
        value: 3,
        label: '西安'
      }, {
        value: 4,
        label: '长春'
      }],
      areaValue: 1,
      monthValue: getDate(),
      orderNumberCd: 0,
      orderNumberXa: 0,
      orderNumberZb: 0,
      checkNumberCd: 0,
      checkNumberXa: 0,
      checkNumberZb: 0,
      deApprovalNumberZb: [],
      deApprovalNumberCd: [],
      deApprovalNumberXa: [],
      navList: [{
        id: 0,
        name: "全部"
      }, {
        id: 1,
        name: "省内"
      }, {
        id: 2,
        name: "省外"
      }],
      activeIndex: 0
    }
  },
  mounted() {
    this.examineStatistics()
  },
  methods: {
    navItem(index) {
      this.activeIndex = index
    },
    areaChange(e) {
      this.areaValue = e
    },
    monthChange(e) {
      this.monthValue = e
    },
    exportLogisticsInfos() {
      let params = {
        month: this.monthValue,
        province: this.activeIndex,
        regionId: this.areaValue
      }
      const loading = this.$loading({
        lock: true,
        text: '正在导出Excel表格，请耐心等待！',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      exportLogisticsInfos(params).then(res => {

        if (res.code === 0) {
          if (res.data.length > 0) {
            this.exportExcel(res.data)
            loading.close();
          } else {
            this.$message.info("暂无数据！")
            loading.close();
          }
        } else {
          this.$message.error(res.desc)
        }
      })
    },
    exportExcel(list) {


      this.downloadLoading = true
      let tHeader = [
        "备品类型",
        "提货单编号",
        "单位简称",
        "提货金额",
        "收货人地址",
        "收货人姓名",
        "收货人电话",
        "发货件数",
        "发货物流",
        "运单号",
        "物流电话",
        "发出时间",
        "运费",
        "是否免费",
        "发货人",
        "重量",
        "体积",
        "计费方式",
        "备注",
        "备注2",
        "是否送货上门",
        "状态",
        "录入日期"
      ]


      import('@/vendor/Export2Excel').then(excel => {
        const data = this.formatJson([
          "sparePartsTypeName",
          "orderNum",
          "unitName",
          "totalAmount",
          "address",
          "receiver",
          "receiverPhone",
          "num",
          "logisticsCompanyName",
          "trackingNumber",
          "logisticsCompanyPhone",
          "deliveryDate",
          "freight",
          "isFree",
          "consignorName",
          "weight",
          "volume",
          "billingMethod",
          "remark",
          "remark2",
          "isVisit",
          "status",
          "entryDate"
        ], list)
        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: '物流明细单',
          autoWidth: true,
          bookType: 'xlsx'
        })
        this.downloadLoading = false

        this.dialogExportVisible = false
      })


    },
    formatJson(filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => {

        if (j === "isVisit") {
          return v[j] === 1 ? "是" : "否"
        }
        if (j === "isFree") {
          return v[j] === 1 ? "是" : "否"
        }

        if (j === 'status') {
          let text = ''
          switch (v[j]) {
            case -1:
              text = '未提交'
              break
            case 1:
              text = '待审核'
              break
            case 2:
              text = '已通过'
              break
            case 3:
              text = '未通过'
              break
          }
          return text
        }

        if (j === 'billingMethod') {
          let text = ''
          switch (v[j]) {
            case 1:
              text = '重量'
              break
            case 2:
              text = '体积'
              break
            case 3:
              text = '发货件数'
              break
            case 4:
              text = '重抛'
              break
            case 5:
              text = '整车'
              break
          }
          return text
        }

        return v[j]
      }))
    },

    downloadOrderNumList(e, region) {
      if (e.length <= 0) {
        this.$message.info("当前选项无数据")
      } else {
        const data = this.formatJsonOrderNum(['orderNum'], e)
        import('@/vendor/Export2Excel').then(excel => {
          excel.export_json_to_excel({
            header: ['提货单号'],
            data,
            filename: '反审核提货单号-' + region,
            autoWidth: true,
            bookType: 'xlsx'
          })
        })
      }
    },
    formatJsonOrderNum(filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => {
        if (j === 'orderNum') {
          return v[j]
        }
      }))
    },
    examineStatistics() {
      examineStatistics().then(res => {
        if (res.code == 0) {
          let orderList = res.data
          orderList.forEach((item) => {
            if (item.name == '淄博') {
              this.orderNumberZb = item.orderNumber
              this.checkNumberZb = item.checkNumber
            }
            if (item.name == '西安') {
              this.orderNumberXa = item.orderNumber
              this.checkNumberXa = item.checkNumber
            }
            if (item.name == '成都') {
              this.orderNumberCd = item.orderNumber
              this.checkNumberCd = item.checkNumber
            }
          })
        } else {
          this.$message.error(res.desc)
        }
      })


      deApprovalStatistics().then(res => {
        if (res.code == 0) {
          let orderList = res.data
          if (orderList !== null) {
            orderList.forEach((item) => {
              if (item.name == '淄博') {
                this.deApprovalNumberZb = item.orderNumberList
              }
              if (item.name == '西安') {
                this.deApprovalNumberXa = item.orderNumberList
              }
              if (item.name == '成都') {
                this.deApprovalNumberCd = item.orderNumberList
              }
            })
          }

        } else {
          this.$message.error(res.desc)
        }
      })


    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/statistics.scss";
</style>
