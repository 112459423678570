import request from '@/utils/request'

export function consignorRanking(query) {
    return request({
        url: '/logisticsStatistics/consignorRanking',
        method: 'get',
        params: query
    })
}
export function shipperRanking(query) {
    return request({
        url: '/logisticsStatistics/shipperRanking',
        method: 'get',
        params: query
    })
}
export function shipperRankingByConsignor(query) {
    return request({
        url: '/logisticsStatistics/shipperRankingByConsignor',
        method: 'get',
        params: query
    })
}

export function companyRanking(query) {
    return request({
        url: '/logisticsStatistics/companyRanking',
        method: 'get',
        params: query
    })
}

export function regionTotalFreight(query) {
    return request({
        url: '/logisticsStatistics/regionTotalFreight',
        method: 'get',
        params: query
    })
}

export function examineStatistics() {
    return request({
        url: '/logisticsStatistics/examineStatistics',
        method: 'get'
    })
}

export function deApprovalStatistics() {
    return request({
        url: '/logisticsStatistics/deApprovalStatistics',
        method: 'get'
    })
}
export function totalFreight() {
    return request({
        url: '/logisticsStatistics/totalFreight',
        method: 'get'
    })
}
export function logisticsDoubt(query) {
    return request({
        url: '/logisticsStatistics/logisticsDoubt',
        method: 'get',
        params: query
    })
}

export function assessment(query) {
    return request({
        url: '/logisticsStatistics/assessment',
        method: 'get',
        params: query
    })
}

export function logisticsDoubtByOrderNumber(query) {
    return request({
        url: '/logisticsStatistics/logisticsDoubtByOrderNumber',
        method: 'get',
        params: query
    })
}

export function proportionOfMonth() {
    return request({
        url: '/logisticsStatistics/proportionOfMonth',
        method: 'get'
    })
}
export function exportLogisticsInfos(query) {
    return request({
        url: '/logisticsStatistics/exportLogisticsInfos',
        method: 'get',
        params: query
    })
}
export function insertLogisticsChecked(data) {
    return request({
        url: '/logisticsStatistics/insertLogisticsChecked',
        method: 'post',
        data
    })
}


export function deleteLogisticsChecked(data) {
    return request({
        url: '/logisticsStatistics/deleteLogisticsChecked',
        method: 'post',
        data
    })
}
export function insertLogisticsCheckedByInvoiceId(invoiceId) {
    return request({
        url: '/logisticsStatistics/insertLogisticsCheckedByInvoiceId',
        method: 'post',
        params: {invoiceId}
    })
}

