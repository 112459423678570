<template>
  <div class="statistics-card query">
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-ruleForm">
      <el-form-item label="" prop="code">
        <el-select v-model="ruleForm.code" placeholder="请选择类型">
          <el-option label="运单号" value="1"></el-option>
          <el-option label="提货单号" value="2"></el-option>
        </el-select>
        <el-select v-model="ruleForm.regionId" placeholder="请选择地区" style="margin-top: 10px">
          <el-option label="淄博" value="1"></el-option>
          <el-option label="成都" value="2"></el-option>
          <el-option label="西安" value="3"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="" prop="keyword">
        <el-input v-model="ruleForm.keyword" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')">搜索</el-button>
      </el-form-item>
    </el-form>
    <orderDetails ref="orderDetails" :queryVal="queryVal"></orderDetails>
  </div>
</template>

<script>
import orderDetails from "./orderDetails.vue"

export default {
  components: {
    orderDetails
  },
  data() {
    return {
      ruleForm: {
        code: '',
        keyword: '',
        regionId:null
      },
      queryVal: {},
      rules: {
        code: [{
          required: true,
          message: '请选择',
          trigger: 'change'
        }],
        regionId: [{
          required: true,
          message: '请选择地区',
          trigger: 'change'
        }],
        keyword: [{
          required: true,
          message: '请输入',
          trigger: 'blur'
        }]
      }
    }
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$refs.orderDetails.show()
          this.queryVal = this.ruleForm
          this.ruleForm = {
            code: '',
            keyword: ''
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
  }
}
</script>

<style lang="scss" scoped>
.query {
  height: 400px;
  box-sizing: border-box;
  display: flex;
  align-items: center;

  .el-select,
  .el-input,
  .el-button {
    width: 100%;
  }
}
</style>
