<template>
  <div class="area-proportion">
    <el-row :gutter="15">
      <el-col :span="6" v-for="(item,index) in areaList" :key="index">
        <div class="area-item">
          <div style="width:0px; height: 0px;">
            <svg width="100%" height="100%">
              <defs>
                <linearGradient id="blue" x1="0%" y1="0%" x2="100%" y2="0%">
                  <stop offset="0%" style="stop-color:#0e7dff" stop-opacity="1"></stop>
                  <stop offset="100%" style="stop-color:#c5e5ff" stop-opacity="1"></stop>
                </linearGradient>
                <linearGradient id="orange" x1="0%" y1="100%" x2="0%" y2="0%">
                  <stop offset="0%" style="stop-color:#FF6A16" stop-opacity="1"></stop>
                  <stop offset="100%" style="stop-color:#FFC83A" stop-opacity="1"></stop>
                </linearGradient>
              </defs>
            </svg>
          </div>
          <div class="progress-wrapper">
            <div class="background"></div>
            <el-progress type="dashboard" :percentage="item.proportion" color="#FF6A16" :width="105" :stroke-width="20"
                         define-back-color="#D0EBFF" text-color="#2388FF"></el-progress>
          </div>
          <div class="name">{{ item.regionName }}</div>
          <p>货值：<span>{{ item.totalAmount }}</span>万元</p>
          <p>运费：<span>{{ item.totalFreight.toFixed(2) }}</span>元</p>
          <p>运单：<span>{{ item.totalNum }}</span>笔</p>
          <!-- <div class="main-nav">
            <div class="nav-item" :class="item.activeIndex==indexs?'active':''" v-for="(items,indexs) in navList"
              :key="items.id" @click="navItem(index,indexs)">{{items.name}}</div>
          </div> -->
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import {totalFreight} from '@/api/statistic'

export default {
  data() {
    return {
      areaList: [],
      navList: [{
        id: 1,
        name: "省内"
      }, {
        id: 2,
        name: "省外"
      }],
    };
  },
  mounted() {
    this.getData()
  },
  methods: {
    navItem(index, indexs) {
      this.areaList[index].activeIndex = indexs
    },
    getData() {
      totalFreight().then(res => {
        if (res.code == 0) {
          // if (res.data.length < 4){
          //   res.data.push({
          //     regionName: '长春',
          //     proportion: 0,
          //     totalFreight: 0,
          //     totalAmount: 0,
          //     totalNum: 0
          //   })
          // }
          this.areaList = res.data

        } else {
          this.$message.error(res.desc)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/statistics.scss";

::v-deep .el-progress-circle {
  svg > path:nth-child(1) {
    stroke: url(#blue);
  }

  svg > path:nth-child(2) {
    stroke: url(#orange);
  }
}
</style>
