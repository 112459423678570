<template>
	<div class="overall-data">
		<el-row :gutter="15">
			<el-col :span="6" style="margin-bottom: 10px;">
				<div class="overall-data-item">
					<div class="number">{{ statisticsResult.totalAmount == null? 0:  (statisticsResult.totalAmount / 10000).toFixed(2) }}</div>
					<span>（万元）</span>
					<div class="title">总货值</div>
				</div>
			</el-col>
			<el-col :span="6">
				<div class="overall-data-item" style="margin-bottom: 10px;">
					<div class="number">{{ statisticsResult.totalFreight == null? 0:  (statisticsResult.totalFreight / 10000).toFixed(2) }}</div>
					<span>（万元）</span>
					<div class="title">总运费</div>
				</div>
			</el-col>
			<el-col :span="6">
				<div class="overall-data-item" style="margin-bottom: 10px;">
					<div class="number">{{ statisticsResult.totalNum == null? 0:  statisticsResult.totalNum }}</div>
					<span>（笔）</span>
					<div class="title">总运单</div>
				</div>
			</el-col>
			<el-col :span="6">
				<div class="overall-data-item" style="margin-bottom: 10px;">
					<div class="number">{{ statisticsResult.proportion == null? 0:  statisticsResult.proportion.toFixed(2) }}%</div>
					<div class="rate">
						同比：
						<span class="green" v-if="rate<=0">{{statisticsResult.lastYearProp == null? 0:  statisticsResult.lastYearProp.toFixed(2)}}%<img src="../../../assets/img/down.png"/></span>
						<span class="red" v-if="rate>0">{{statisticsResult.lastYearProp == null? 0:  statisticsResult.lastYearProp.toFixed(2)}}%<img src="../../../assets/img/up.png"/></span>
					</div>
					<div class="title">物流费总占比</div>
				</div>
			</el-col>
      <el-col :span="6">
        <div class="overall-data-item">
          <div class="rate" style="margin-bottom: 10px;">
            大件占比:
            <span class="red" style="font-size:18px;">{{ statisticsResult.largeItemProp }}%</span>
            <el-tooltip class="item" effect="light" placement="top">
              <div slot="content">
                订货备件代码：<br/>
                1000410,1700020,1700030,1700040,1700210,1700940,1700950,<br/>1700970,2400010,2500010,2800010,3000010,3524010,<br/>3524015,5000020,5000990,5100010,5300010,5400005,<br/>5400010,5600010,5700010,6DF2-27,6DL2-35,6DL2J-3,<br/>6DM2-39,6DM2-42,6DM2-46,6DM3-50,6DM3-55,BF6M201,<br/>H57-24E,H57-26E
              </div>
              <i class="el-icon-warning-outline"></i>
            </el-tooltip>
          </div>
          <div class="rate" style="margin-bottom:6px;">
            散件占比:
            <span class="red" style="font-size:18px;">{{ statisticsResult.otherItemProp }}%</span>
          </div>
          <div class="title">货值占比</div>
        </div>
      </el-col>
			<el-col :span="6">
				<div class="overall-data-item">
					<div class="number">0</div>
					<span>（万元）</span>
					<div class="title">基准运费</div>
				</div>
			</el-col>
			<el-col :span="6">
				<div class="overall-data-item">
					<div class="number">0</div>
					<span>（万元）</span>
					<div class="title">较基准降低运费</div>
				</div>
			</el-col>
			<el-col :span="6">
				<div class="overall-data-item">
					<div class="number">0</div>
					<span>（金牌）</span>
					<div class="title">物流费用综合评分</div>
				</div>
			</el-col>
		</el-row>
		<!-- <div class="classify">
			<div class="classify-item">
				<div class="name">吨</div>
				<div class="number">{{ statisticsResult.weightProp == null? 0:  statisticsResult.weightProp.toFixed(2) }}</div>
			</div>
			<div class="classify-item">
				<div class="name">件</div>
				<div class="number">{{ statisticsResult.numProp == null? 0:  statisticsResult.numProp.toFixed(2) }}</div>
			</div>
			<div class="classify-item">
				<div class="name">方</div>
				<div class="number">{{ statisticsResult.volumeProp == null? 0:  statisticsResult.volumeProp.toFixed(2) }}</div>
			</div>
			<div class="classify-item">
				<div class="name">重抛</div>
				<div class="number">{{ statisticsResult.zpProp == null? 0:  statisticsResult.zpProp.toFixed(2) }}</div>
			</div>
			<div class="classify-item">
				<div class="name">整车</div>
				<div class="number">{{ statisticsResult.zcProp == null? 0:  statisticsResult.zcProp.toFixed(2) }}</div>
			</div>
		</div> -->
		<div class="tips">
			<span>以上数据不含对方付款：{{ statisticsResult.notInTotalFreight == null? 0:  statisticsResult.notInTotalFreight.toFixed(2) }}元</span><span>运单：{{ statisticsResult.notInTotalNum == null? 0:  statisticsResult.notInTotalNum.toFixed(2) }}笔</span>
			<span style="margin-left:40px">第三方物流费用：淄博{{ statisticsResult.costZb == null? 0:  statisticsResult.costZb.toFixed(2) }}元，</span>
      <span>成都：{{ statisticsResult.costCd == null? 0:  statisticsResult.costCd.toFixed(2) }}元，</span>
      <span>西安：{{ statisticsResult.costXa == null? 0:  statisticsResult.costXa.toFixed(2) }}元</span>
		</div>
	</div>
</template>

<script>
import  {getDate} from '@/common/common'
import {regionTotalFreight} from '@/api/statistic'

export default {
  props: ['regionId', 'month', 'province'],
  data() {
    return {
      rate: -0.25,
      queryData: {
        regionId: 1,
        month: getDate(),
        province: 0
      },
      statisticsResult: {
        totalAmount: 0,
        largeItemProp: 0,
        otherItemProp: 0
      }
    }
  },
  watch: {
    regionId() {
      this.queryData.regionId = this.regionId
      this.getData()
    },
    month() {
      this.queryData.month = this.month
      this.getData()
    },
    province() {
      this.queryData.province = this.province
      this.getData()
    },

  },
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      regionTotalFreight(this.queryData).then(res => {
        if (res.code == 0) {
          this.statisticsResult = res.data
          this.statisticsResult.largeItemProp = (this.statisticsResult.largeItemAmount * 100 / this.statisticsResult.totalAmount).toFixed(2)
          if (this.statisticsResult.largeItemProp === "NaN") {
            this.statisticsResult.largeItemProp = 0
            this.statisticsResult.otherItemProp = 0
          } else {
            this.statisticsResult.otherItemProp = 100 -this.statisticsResult.largeItemProp
          }

        } else {
          this.$message.error(res.desc)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
	@import "~@/assets/css/statistics.scss";
</style>
