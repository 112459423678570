<template>
	<div class="proportion-charts">
		<div class="statistics-title">
			<span class="title">年度运费占比趋势图(%)</span>
		</div>
		<div id="proportionCharts"></div>
	</div>
</template>

<script>
import {proportionOfMonth} from '@/api/statistic'
	export default {
		data() {
			return {
				option: {
					backgroundColor: "#FFF",
					tooltip: {
						trigger: 'axis',
						axisPointer: {
							type: 'shadow'
						}
					},
					grid: {
						top: '4%',
						left: '3%',
						right: '5%',
						bottom: '2%',
						containLabel: true
					},
					xAxis: {
						type: 'value',
						boundaryGap: [0, 0.01],
						axisLine: {
							show: true,
							lineStyle: {
								color: "#555",
								type: "dashed"
							}
						},
						splitLine: {
							show: true,
							lineStyle: {
								type: "dashed"
							}
						},
					},
					yAxis: {
						type: 'category',
						axisTick: {
							show: false
						},
						axisLine: {
							show: true,
							lineStyle: {
								color: "#555",
								type: "dashed"
							}
						},
						splitLine: {
							show: true,
							lineStyle: {
								type: "dashed"
							}
						},
						data: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12']
					},
					series: [{
						name: '年度运费占比',
						type: 'bar',
						barWidth: 12,
						itemStyle: {
							color: "#2388FF"
						},
						label: {
							normal: {
								show: true,
								position: "right",
								textStyle: {
									color: "#999"
								},
							},
						},
						data: [10, 20, 30, 45, 78, 88, 99, 10, 25, 38, 78, 8]
					}]
				}
			}
		},
		mounted() {
      this.getData()

		},
		methods: {
			initCharts() {
				// 基于准备好的dom，初始化echarts实例
				var myChart = this.$echarts.init(document.getElementById('proportionCharts'));
				// 绘制图表
				myChart.setOption(this.option);
			},
      getData() {
        let that = this
        proportionOfMonth().then(res =>{
          if (res.code == 0) {
            that.option.yAxis.data = res.data.map(e=>e.month)
            that.option.series[0].data = res.data.map(e=>e.proportion)
            that.initCharts()
          } else {
            this.$message.error(res.desc)
          }
        })
      }
		}
	}
</script>

<style lang="scss" scoped>
	@import "~@/assets/css/statistics.scss";

	#proportionCharts {
		height: 340px;
	}
</style>
