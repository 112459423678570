<template>
  <div class="shipper-ranking">
    <div class="statistics-title">
      <span class="title">发货员货值运费详情</span>
    </div>
    <el-table :data="tableData" class="statistics-table" height="340" v-loading="loading">
      <el-table-column prop="consignorName" label="序号" width="60" align="center">
        <template slot-scope="scope">
          <div class="title-index">
            <i class="icon"
               :class="{'orange':scope.$index%5==1,'yellow':scope.$index%5==2,'green':scope.$index%5==3,'red':scope.$index%5==4}">{{
                scope.$index + 1
              }}</i>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="consignorName" label="发货员" align="center">
        <template slot-scope="scope">
          <span class="link" @click="shipperDetail(scope.row)">{{ scope.row.consignorName }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="totalAmount" label="货值" align="center"></el-table-column>
      <el-table-column prop="amountProp" label="货值占比(个人货值/公司货值)" align="center" min-width="130">
        <template slot-scope="scope">
          {{ scope.row.amountProp }}%
        </template>
      </el-table-column>
      <el-table-column prop="num" label="运单数" align="center" width="60"></el-table-column>
      <el-table-column prop="totalFreight" label="运费" align="center" width="120"></el-table-column>
      <el-table-column prop="freightProp" label="运费占比(运费/货值)" align="center" min-width="100">
        <template v-slot="{row}">
          {{ row.freightProp }}%
        </template>
      </el-table-column>
    </el-table>
    <el-dialog :title="consignorName" :visible.sync="dialogVisible" width="70%" :before-close="handleClose">
      <el-table :data="tableDetailData" class="main-table" header-row-class-name="table-header" highlight-current-row
                max-height="550" v-loading="loading">
        <el-table-column label="序号" type="index" align="center" width="60" fixed></el-table-column>
        <el-table-column prop="companyName" label="物流公司" align="center">
        </el-table-column>
        <el-table-column prop="totalAmount" label="货值" align="center"></el-table-column>
        <el-table-column prop="amountProp" label="货值占比(物流货值/个人货值)" align="center" min-width="120">
          <template v-slot="{row}">
            {{ row.amountProp }}%
          </template>
        </el-table-column>
        <el-table-column prop="num" label="运单数" align="center" width="60"></el-table-column>
        <el-table-column prop="totalFreight" label="运费" align="center" width="120"></el-table-column>
        <el-table-column prop="freightProp" label="运费占比(物流运费/物流货值)" align="center" min-width="120">
          <template v-slot="{row}">
            {{ row.freightProp }}%
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import {
  shipperRanking, shipperRankingByConsignor
} from "@/api/statistic"
import {getDate} from "@/common/common";

export default {
  props: ['regionId', 'month', 'province'],
  watch: {
    regionId() {
      this.queryData.regionId = this.regionId
      this.getData()
    },
    month() {
      this.queryData.month = this.month
      this.getData()
    },
    province() {
      this.queryData.province = this.province
      this.getData()
    },
  },
  data() {
    return {
      queryData: {
        regionId: 1,
        month: getDate(),
        province: 0
      },
      dialogVisible: false,
      tableData: [],
      tableDetailData: [],
      loading: false,
      consignorName: '',
      totalAmount: ''
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    shipperDetail(row) {
      this.dialogVisible = true
      let params = this.queryData
      params.consignorId = row.id
      this.consignorName = '发货员货值运费详情-' + row.consignorName
      this.totalAmount = row.totalAmount
      shipperRankingByConsignor(params).then(res => {
        if (res.code == 0) {
          this.tableDetailData = res.data
          this.loading = false
        } else {
          this.$message.error(res.desc)
        }
      })
    },
    getData() {
      this.loading = true
      shipperRanking(this.queryData).then(res => {
        if (res.code == 0) {
          this.tableData = res.data
          this.loading = false
        } else {
          this.$message.error(res.desc)
        }
      })
    }
  }
}
</script>


<style lang="scss" scoped>
@import "~@/assets/css/statistics.scss";

.shipper-ranking {
  padding: 15px;
  background: #E3EFFF;

  .title-index {
    justify-content: center;
  }

  .link {
    cursor: pointer;
    color: $main-color;
    text-decoration: underline;
  }
}
</style>
